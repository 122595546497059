import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken, NodePOSTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [NotificationToken, setNotificationToken] = React.useState(FetchData.meta.notification_token)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)

  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)

  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const [AddNewBook, setAddNewBook] = React.useState(false)
  const [VerifiedBook, setVerifiedBook] = React.useState([])
  const [BookDeleteIndex, setBookIndex] = React.useState("")
  const [BookDeleteModal, setBookDeleteModal] = React.useState(false)

  const [batchList, setBatchList] = React.useState([])
  const [batchSelectListModal, setBatchSelectListModal] = React.useState(false)
  const [batchSelectList, setBatchSelectList] = React.useState([])
  const [batchCacheList, setBatchCacheList] = React.useState([])
  const [batchID, setBatchID] = React.useState("")
  const [batchPrice, setBatchPrice] = React.useState("")
  const [batchUpto, setBatchUpto] = React.useState("")
  const [batchName, setBatchName] = React.useState("")

  const [toStudentModel, setToStudentModal] = React.useState(false)


  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": UserRole,
        "user_profile_picture": ProfilePicture,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "notification_token": NotificationToken,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "user_verification_history": userVerificationHistory,
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)

          // dispatch(login(
          //   `${Login.id}`,
          //   `${Login.userName}`,
          //   `${window.btoa(`${userName}:${Password}`)}`,
          //   `${Login.userEmail}`,
          //   `${Login.userRole}`,
          //   `${Login.userPhoto}`,
          //   `${Login.userPhoneNumber}`,
          //   `${Login.userBatch}`,
          // ))
          // AdsPUTApi(dataWithPassword, `users`, `${userID}`)
          //   .then(res => {
          //     AfterUserCreate(userID)
          //   })
          //   .catch(e =>
          //     console.log(e)
          //   )
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  useEffect(() => {
    BatchList()
    BatchOrders()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.category_name" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const BatchOrders = () => {
    AdsGETApiAuth(
      {
        per_page: 100, _fields: "id,title,meta",
        "filter[meta_query][1][key]": "student_id_order",
        "filter[meta_query][1][value][0]": userID,
        "filter[meta_query][1][compare]": "=",
      },
      slugs.db_slug_batch_orders
    )
      .then((res) => {
        setVerifiedBook(res.data)
        // setBatchCacheList(res.data)
        // var cache = []
        // for (let i = 0; i < res.data.length; i++) {
        //   var item = res.data[0]
        //   batchCacheList.push({
        //     batch_id: item.meta.batch_id_order,
        //     name: item.title.rendered,
        //     exam_type: "",
        //     slug: item.slug
        //   })
        // }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const AddBatch = (data) => {
    setReload(true)

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_batch_orders)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Order Added Successfully")
          history.push(`${slugs.batch_order_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_batch_orders, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`Order edited Successfully`)
        }).catch(err => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }

  const MappingExam = ({ item }) => {
    var allArray = Object.values(batchCacheList).concat(Object.values(VerifiedBook))
    const EnrollmentSearch = allArray.filter(a => a.meta.batch_id_order == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      if (!CheckON)
        setCheckON(true)
      else
        setCheckON(false)
      var allOrders = batchCacheList
      if (batchCacheList.filter(a => a.meta.batch_id_order == item.id).length == 0) {
        var data = {
          "title": `${ID}-${item.id}`,
          "status": "publish",
          "meta": {
            "batch_name_order": `${item.title.rendered}`,
            "batch_id_order": `${item.id}`,
            "student_id_order": `${userID}`,
            "student_email_order": userEmail,
            "student_phone_order": `${PhoneNumber}`,
            "student_username_order": userName,
            "paid_amount": `0`,
            "amount_to_pay": `0`,
            "verification_status": "Verified",
            "verification_method": "Manual",
            "verification_date": `${batchUpto}`,
            "verification_id": `${batchUpto}`
          }
        }
        console.log(batchCacheList, VerifiedBook)
        batchCacheList.push(data)
        console.log(batchCacheList, VerifiedBook)
        // setVerifiedBook(allOrders)
        // BatchExamIDCache.push(item.id)
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td onClick={() => { clickFunction() }}>{item.id}</td>
        <td onClick={() => { clickFunction() }}>{item.title.rendered}</td>
        <td onClick={() => { clickFunction() }}>{item.meta.category_name}</td>
        {/* <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}
        >Choose</td> */}
      </>
    )
  }
  return (
    <div>{console.log(Login)}
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Student Name </td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Role</td>
                <td className="col-6">
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} />
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"author"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  <div className="input-common"
                    onClick={() => { setNotificationToken("") }}
                  >{NotificationToken} Clear</div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          Change Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Student
              </div>
            </div>
          </div>
        </div>
      </div>
      {Login.userRole == "author" &&
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Batch List</center></h4>
            <table>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>Batch</td>
                  <td>Student Name</td>
                  <td>Verification</td>
                  <td>Amount</td>
                  {/* <td>Delete</td> */}
                </tr>
                {VerifiedBook.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.meta.batch_name_order}</td>
                    {/* <td>{item.meta.batch_id_order}</td> */}
                    <td>{item.meta.student_username_order}</td>
                    <td>{item.meta.verification_status}</td>
                    <td>{item.meta.paid_amount}</td>
                    {/* <td style={{ cursor: "pointer" }} onClick={() => {
                      setBookIndex(index)
                      setBookDeleteModal(true)
                    }}>Delete</td> */}
                  </tr>
                ))}

              </tbody>
            </table>
            <div className="row center">
              <div className="col-3 center " >
                <div className="sign-in-button-4" style={{ width: "80%" }}
                  onClick={() => {
                    setToStudentModal(true)
                  }}
                >
                  Add New
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                  }}
                >X</div>
                <tr>
                  <td className="col-3">Valid Upto</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={batchUpto} placeholder="Name" type="date"
                      onChange={(event) => { setBatchUpto(event.target.value) }}
                    />
                  </td>
                </tr>

                {batchSelectList.map((item, index) => (
                  <span key={index}>{item.name} , </span>
                ))}

                Batches List
                <div style={{ height: "50vh", overflowY: "scroll", }}>
                  <table style={{}}>
                    <thead>
                      <tr>
                        <td>SN</td>
                        <td>Batch</td>
                        <td>Batch ID</td>
                        <td>Category</td>
                        {/* <td>Role</td> */}
                      </tr>
                      {batchList.map((item, index) => (
                        <tr key={index}>
                          <MappingExam item={item} />
                        </tr>
                      ))}
                    </thead>
                  </table>
                </div>
                <div className="row center">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        // NodePOSTApi({
                        //   batchList: batchSelectList,
                        //   batchPrice: batchPrice,
                        //   dateUpto: batchUpto
                        // }, "register-students")
                        //   .then(res => {
                        //     console.log(res.data)
                        //     alert(`${res.data.data} students added`)
                        //     setReload(false)
                        //     setToStudentModal(false)
                        //   })
                        //   .catch(err => {
                        //     console.log(err)
                        //   })
                        for (let i = 0; i < batchCacheList.length; i++) {
                          setReload(true)
                          AdsPOSTApi(batchCacheList[i], slugs.db_slug_batch_orders)
                            .then((res) => {
                              // setReload(false)
                              // setID(res.data.id)
                              // setAlertShow(true)
                              // setAlertcomp("Order Added Successfully")
                              // history.push(`${slugs.batch_order_details}${res.data.slug}`)
                              if (i == batchCacheList.length - 1) {
                                setReload(false)
                                BatchOrders()
                                setToStudentModal(false)
                                window.location.reload(false);
                              }
                            }).catch(err => {
                            })
                        }
                      }}
                    >
                      Verify Selected Batch
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }

    </div >
  )

}